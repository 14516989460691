









import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    OSettingsUser: () => import('@/components/organisms/o-settings-user.vue'),
    OSettingsUserPassword: () => import('@/components/organisms/o-settings-user-password.vue'),
  }
})
